<template>
  <v-card flat max-height="70vh" class="card-timeline">
      <v-card flat class="py-2">
          <v-timeline dense v-if="itinerario.length" class="pt-0">
            <v-timeline-item color="primary" fill-dot  class="align-center">
                 <template v-slot:icon>
                    <v-icon size="20" color="white">
                        mdi-arrow-down
                    </v-icon>
                </template>
                <span class="text-subtitle-2 grey--text grey--text text--darken-1">
                    <span class="grey--text">{{itinerario[0].hora}} - </span>
                    {{itinerario[0].nombre}}
                </span>
            </v-timeline-item>
            <v-timeline-item color="primary" small fill-dot v-for="(estacion,index) in EntreTerminales" :key="index"  class="align-center">
                <span class="text-subtitle-2 grey--text grey--text text--darken-1">
                    <span class="grey--text">{{estacion.hora}} - </span>
                    {{estacion.nombre}}
                </span>
            </v-timeline-item>
            <v-timeline-item color="primary" fill-dot class="align-center pb-0">
                 <template v-slot:icon>
                    <v-icon size="20" color="white">
                        mdi-check
                    </v-icon>
                </template>
                <span class="text-subtitle-2 grey--text grey--text text--darken-1">
                    <span class="grey--text">{{itinerario[itinerario.length-1].hora}} - </span>
                    {{itinerario[itinerario.length-1].nombre}}
                </span>
            </v-timeline-item>
        </v-timeline>
      </v-card>
  </v-card>
</template>

<script>
import dayjs from "dayjs";
export default {
    name: 'ServicioItinerario',
    props: ['inicio', 'termino', 'recorrido'],
    data() {
        return {
            itinerario: [],
            itinerario_referencia : [
                {numero: 1, nombre: 'Puerto', tiempo_v1: 0, tiempo_v2: 60},
                {numero: 2, nombre: 'Bellavista', tiempo_v1: 2, tiempo_v2: 58},
                {numero: 3, nombre: 'Francia', tiempo_v1: 4, tiempo_v2: 56},
                {numero: 4, nombre: 'Baron', tiempo_v1: 6, tiempo_v2: 54},
                {numero: 5, nombre: 'Portales', tiempo_v1: 8, tiempo_v2: 52},
                {numero: 6, nombre: 'Recreo', tiempo_v1: 12, tiempo_v2: 48},
                {numero: 7, nombre: 'Miramar', tiempo_v1: 14, tiempo_v2: 46},
                {numero: 8, nombre: 'Viña del Mar', tiempo_v1: 16, tiempo_v2: 44},
                {numero: 9, nombre: 'Hospital', tiempo_v1: 18, tiempo_v2: 42},
                {numero: 10, nombre: 'Chorrillos', tiempo_v1: 20, tiempo_v2: 40},
                {numero: 11, nombre: 'El Salto', tiempo_v1: 22, tiempo_v2: 38},
                {numero: 12, nombre: 'Quilpue', tiempo_v1: 35, tiempo_v2: 25},
                {numero: 13, nombre: 'El Sol', tiempo_v1: 37, tiempo_v2: 23},
                {numero: 14, nombre: 'El Belloto', tiempo_v1: 40, tiempo_v2: 20},
                {numero: 15, nombre: 'Las Americas', tiempo_v1: 42, tiempo_v2: 18},
                {numero: 16, nombre: 'La Concepcion', tiempo_v1: 44, tiempo_v2: 16},
                {numero: 17, nombre: 'Villa Alemana', tiempo_v1: 46, tiempo_v2: 14},
                {numero: 18, nombre: 'S. Aldea', tiempo_v1: 48, tiempo_v2: 12},
                {numero: 19, nombre: 'Peñablanca', tiempo_v1: 50, tiempo_v2: 10},
                {numero: 20, nombre: 'Limache', tiempo_v1: 60, tiempo_v2: 0},

            ]
        }
    },
    computed:{
        LargoItinerario(){
            return this.itinerario.length
        },
        EntreTerminales(){
            let entre_terminales
            if(this.itinerario.length) entre_terminales = this.itinerario.slice(1, -1)
            return entre_terminales
        }
    },
    methods:{
        GetItinerario(){
            switch (this.recorrido) {
                case 'pu-li':
                    this.ItinerarioTiempos(1, 1, 20)
                    break
                case 'li-pu':
                    this.ItinerarioTiempos(2, 20, 1)
                    break
                case 'pu-eb':
                    this.ItinerarioTiempos(1, 1, 14)
                    break
                case 'eb-pu':
                    this.ItinerarioTiempos(2, 14, 1)
                    break
                case 'pu-sa':
                    this.ItinerarioTiempos(1, 1, 18)
                    break
                case 'sa-pu':
                    this.ItinerarioTiempos(2, 18, 1)
                    break
                case 'sa-mi':
                    this.ItinerarioTiempos(2, 18, 7)
                    break
                case 'po-sa':
                    this.ItinerarioTiempos(1, 5, 18)
                    break
                case 'li-ba':
                    this.ItinerarioTiempos(2, 20, 4)
                    break
                default:
                    break
            }
        },
        ItinerarioTiempos(via, estacion_inicio, estacion_termino){
            this.itinerario = []
            let index_estacion_inicio = this.itinerario_referencia.findIndex(estacion => estacion.numero === estacion_inicio),
                index_estacion_termino = this.itinerario_referencia.findIndex(estacion => estacion.numero === estacion_termino)
            if(via == 1){
                let primera_estacion = index_estacion_inicio
                for (let index = index_estacion_inicio; index <= index_estacion_termino; index++) {
                    let hora
                    if(primera_estacion == index){
                        hora = dayjs().hour(this.inicio.slice(0, -3)).minute(this.inicio.slice(-2)).format('HH:mm')
                    }else{
                        if(primera_estacion !== 1) hora = dayjs().hour(this.inicio.slice(0, -3)).minute(this.inicio.slice(-2)).add(this.itinerario_referencia[index].tiempo_v1, 'minutes').subtract(this.itinerario_referencia[index_estacion_inicio].tiempo_v1, 'minutes').format('HH:mm')
                        else hora = dayjs().hour(this.inicio.slice(0, -3)).minute(this.inicio.slice(-2)).add(this.itinerario_referencia[index].tiempo_v1, 'minutes').format('HH:mm')
                    }
                    let estacion = {
                        nombre: this.itinerario_referencia[index].nombre,
                        hora: hora
                    }
                    this.itinerario.push(estacion)
                }
            }
            if(via == 2) {
                let primera_estacion = index_estacion_inicio
                for (let index = index_estacion_inicio; index >= index_estacion_termino; index--) {
                    let hora
                    if(primera_estacion == index){
                        hora = dayjs().hour(this.inicio.slice(0, -3)).minute(this.inicio.slice(-2)).format('HH:mm')
                    }else{
                        if(primera_estacion !== 20) hora = dayjs().hour(this.inicio.slice(0, -3)).minute(this.inicio.slice(-2)).add(this.itinerario_referencia[index].tiempo_v2, 'minutes').subtract(this.itinerario_referencia[index_estacion_inicio].tiempo_v2, 'minutes').format('HH:mm')
                        else hora = dayjs().hour(this.inicio.slice(0, -3)).minute(this.inicio.slice(-2)).add(this.itinerario_referencia[index].tiempo_v2, 'minutes').add(this.itinerario_referencia[index].tiempo_v2, 'minutes').format('HH:mm')
                    }
                    let estacion = {
                        nombre: this.itinerario_referencia[index].nombre,
                        hora: hora
                    }
                    this.itinerario.push(estacion)
                }
            }
        }
    },
    watch:{
        inicio: function (val){
            if(val) this.GetItinerario()
        }
    }
    

}
</script>

<style scoped>

.card-timeline{
    will-change: transform !important;
    scroll-behavior: smooth;
    overflow: auto
}

.v-timeline-item__dot{
    box-shadow: 0 !important;
}
</style>